@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');


:root {
  --primary-white: #ffffff;
  --primary-orange: #f9ab00; 
  --primary-dark: rgba(255,255,255,0);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-hover-blue: #4a6a93;
  --primary-gray: #ccd1d9;
  --primary-light-gray: #616161;
  --primary-dark-gray: #212529;
}

body{
  font-family: 'Roboto Condensed', sans-serif I !important;
  background-color: var(--primary-dark-gray);
  color: var(--primary-white) !important;
}

#content {
  justify-content: center;
  align-items: center;
  padding-right: 5rem;
  padding-left: 5rem;
}

.blog h2 {
  text-transform: uppercase;
  color: var(--primary-orange);
  margin-top: 5rem;
}

/******************************* NAVBAR ***********************/
nav {
  z-index: 2;
}

/******************************* MENU LINKS ********************/

nav ul li{
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-nav > li > a.active, .navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

@media(max-width: 768px) { 
  .socialM {
    display: flex;
    flex-direction: row;
  }
}


/******************************* Header ***********************/

.header-wraper {
  position: relative;
  background: url(0NYZMJj.jpeg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-orange);
  text-transform: uppercase;
  font-weight: bold;
}

#tsparticles {
  position: absolute; 
  z-index: 1;
  width: 100vw;
  height: 85vh;
  top: 8vh;
}

/******************************* Typed-text ***********************/
.typed-text {
  font-size: 2rem; 
  color: var(--primary-white);
}

@media(max-width: 768px) { 
  .main-info h1 { 
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem;
  }
  
  .header-wraper {
    height: 80vh;
  }

  #tsparticles {
    top: 6vh;
    height: 80vh;
  }
}
/******************** Header main offer btn ***********************/
.btn-main-offer {
  text-decoration: none;
  border: 1px solid var(--primary-orange);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  margin: 2rem;
  color: var(--primary-white);
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-orange);
  color: var(--primary-white);
  transition: .2s ease-in-out;
}

/********************* About me **************************/

.photo-wrap {
  display: flex;
}

.profile-img {
  margin-top: 4rem;
 width: 50%;
 border-radius: 50%;
 padding: .1rem;
 border: 1px solid var(--primary-orange);
}

.para {
  text-align: justify;
}

@media(max-width: 468px) {
  .profile-img {
    width: 80%;
    margin-top: 0rem;
  }
  
}


/******************************* EDUCTAION ********************/
.education {
  padding: 0.7rem;
}

.education-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.education-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-gray);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-light-gray);
  background: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-white) !important;
  direction: ltr;
}

.timeline-content h5{
  margin-top: 00.3125rem;
  color: var(--primary-orange);
  font-weight: 500;
}

#thesis_link {
  text-decoration: none;
  color: var(--primary-orange);
}

@media(max-width: 600px) {

  .education .container{
    padding-left: 0;
  }
  .education-wrapper::before {
    left:0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }

  #content {
    padding: 0;
  }

  .photo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #content {
    justify-content: center;
    align-items: center;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .blog h2 {
    text-transform: uppercase;
    color: var(--primary-orange);
    margin-top: 2rem;
  }
}

/******************************* Skills **************************/
.progressBox {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0.5rem;
}

.progressSubBox {
  width: 100%;
  position: relative;
  margin-right: 0.5rem;
}

/******************************* Footer **************************/
.footer {
  background-color: black;
  padding: 8rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-white);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
}

.footer p {
  margin-bottom: 0;
}

@media(max-width: 468px) {
  .footer {
    padding: 3rem;
  }

  .add-email {
    justify-content: center;
  }

  .footer-nav-bar {
    text-align: center;
  }
}

/******************************* contacts **************************/
.blog input, .blog textarea {
  width: 100%;
  box-sizing: border-box;
  background: var(--primary-light-gray);
  resize: none;
  border: 0;
  border-radius: 0;
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  margin-bottom: 1rem;
  color: var(--primary-orange);
}

.blog .container {
  padding: 4rem 0;
}

.blog input:focus, .blog textarea:focus {
  background: var(--primary-light-gray);
  color: var(--primary-orange);
}

.blog textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}